// packages
import { useRouter } from 'next/router'
import { ReactElement } from 'react'
// _app
import { NextPageWithLayout } from './_app'
// mantine
// Containers
import { LoginPageContainer, LandingLayout } from 'Containers'
// types
import { addApolloState, initializeApollo } from 'lib/apolloClient'
// HOC
import { withRedirectIfAuth } from 'HOC'

/**
 * next page component for the default home/landing page
 *
 * @returns Home Page
 */
const Login: NextPageWithLayout = () => {
  const router = useRouter()
  const { redirect } = router.query

  return <LoginPageContainer redirect={redirect as string | undefined} />
}

export const getStaticProps = async () => {
  const apolloClient = initializeApollo()

  return addApolloState(apolloClient, {
    props: {},
    revalidate: 1,
  })
}

Login.getLayout = function getLayout(page: ReactElement) {
  return <LandingLayout>{page}</LandingLayout>
}

export default withRedirectIfAuth({ Component: Login, location: '/' })
